import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppRoutingModule} from "./app-routing.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {AgmCoreModule} from "@agm/core";
import {AgmJsMarkerClustererModule} from "@agm/js-marker-clusterer";
import {MonitorDialog} from "./shared/dialogs/monitor-dialog";
import {ResolverDialog} from "./shared/dialogs/resolver-dialog";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";

import {LOCALE_ID} from "@angular/core";
import {registerLocaleData} from "@angular/common";
import {AppComponent} from "./app.component";
import {LoginLayoutComponent} from "./components/layouts/login-layout.component";
import {AuthGuard} from "./components/auth/auth.guard";
import {InternalAuthService} from "./shared/services/internalauth.service";
import {HttpErrorInterceptor} from "./components/auth/error.interceptor";
import {MaterialModule} from "./material.module";
import {ChecklistService} from "./shared/services/checklist.service";
import {DeleteDialog} from "./shared/dialogs/delete-dialog";
import {ConfirmarDialog} from "./shared/dialogs/confirmar-dialog";
import {ConfirmarDataDialog} from "./shared/dialogs/confirmar-data-dialog";
import { DeleteDataDialog } from "./shared/dialogs/delete-data-dialog";
import {InputDialog} from "./shared/dialogs/input-dialog";
import {ChecklistDialog} from "./shared/dialogs/checklist-dialog";
import {ChangeProtocolDialog} from "./shared/dialogs/changeprotocol-dialog";
import {environment} from "../environments/environment";
import {NewsletterService} from "./shared/services/newsletter.service";
import {ImageDialog} from "./shared/dialogs/image-dialog";
import {GaleryDialog} from "./shared/dialogs/galery-dialog";
import {RoleGuard} from "./components/auth/role.guard";
import {AdminGuard} from "./components/auth/admin.guard";
import {MatIconModule} from "@angular/material/icon";
import {AddComentarioDialog} from "./shared/dialogs/add-comentario-dialog";
import {ServiceWorkerModule} from "@angular/service-worker";
import {OneSignalService} from "./shared/services/oneSignal.service";
import {AtomModule} from "./components/atom/atom.module";
import {NotificarMovimentacaoDialog} from "./shared/dialogs/notificar-movimentacao-dialog";
import {MAT_AUTOCOMPLETE_SCROLL_STRATEGY} from "@angular/material/autocomplete";
import {BlockScrollStrategy, Overlay} from "@angular/cdk/overlay";
import {MonitorProfileGuard} from "./components/auth/monitor.profile.guard";
import {GANTT_GLOBAL_CONFIG, NgxGanttModule} from '@worktile/gantt';
import {AutoCompleteGoogleService} from "./shared/services/autocomplete-google.service";
import {CustomLoaderInterceptor} from "./shared/interceptors/loader-interceptor";
import {GlobalErrorHandler} from "./components/auth/handler/global-error.handler";
import { GenerateCrrDialog } from "./shared/dialogs/generate-crr-dialog";
import { ReprocessarDialog } from "./shared/dialogs/reprocessar-dialog";
import { RenavamSupportGuard } from "./components/auth/renavam-suporte.guard";
import ptBr from "@angular/common/locales/pt";

registerLocaleData(ptBr);

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId,
      },
      initOptions: {
        onLoad: isSafari() ? 'login-required' : 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/sso/silent-check-sso.html',
        silentCheckSsoFallback: !isSafari(),
        checkLoginIframe: !isSafari(),
      },
      enableBearerInterceptor: true,
    });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    MonitorDialog,
    ImageDialog,
    ResolverDialog,
    DeleteDialog,
    DeleteDataDialog,
    ConfirmarDialog,
    ConfirmarDataDialog,
    InputDialog,
    ChecklistDialog,
    GaleryDialog,
    AddComentarioDialog,
    NotificarMovimentacaoDialog,
    GenerateCrrDialog,
    ReprocessarDialog,
    ChangeProtocolDialog
  ],
  imports: [
    NgxGanttModule,
    BrowserModule,
    AppRoutingModule,
    InfiniteScrollModule,
    HttpClientModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    AtomModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production && !isSafari(),
      registrationStrategy: 'registerWhenStable:5000',
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleKey,
      libraries: ["places", "drawing", "geometry"],
    }),
    AgmJsMarkerClustererModule,
    MatIconModule,
    HttpClientModule,
    KeycloakAngularModule,
  ],
  providers: [
    InternalAuthService,
    NewsletterService,
    AuthGuard,
    MonitorProfileGuard,
    RoleGuard,
    AdminGuard,
    RenavamSupportGuard,
    AutoCompleteGoogleService,
    ChecklistService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    OneSignalService,
    {provide: LOCALE_ID, useValue: "pt-PT"},
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: JwtInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // },
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomLoaderInterceptor,
      multi: true
    },
    {
      provide: GANTT_GLOBAL_CONFIG,
      useValue: {
        dateFormat: {
          "day": "dd/MM/yyyy",
          "month": "MM/yyyy",
          "week": "dd/MM/yyyy",
        }
      }
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
