import { Component, OnInit } from "@angular/core";
import { InternalAuthService } from "./shared/services/internalauth.service";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { UsuarioLogged } from "./shared/models/Usuario.model";
import { VeiculoSuporte } from "./shared/models/ServicoVeiculoSuporte.model";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { UserAgentService } from "./shared/services/user-agent.service";
import { AuthService } from '@mottu-ops/sso';
import { ObservabilityService } from './shared/services/observability.service';
import { environment } from '../environments/environment';

export const SKIP_AUTH = ['area-atendimento', 'transferencia-minha-mottu', 'minha-mottu-status-finalizacao', 'fagbot', 'formulario-pagamento', 'formulario-previsao-chegada', 'formulario-aceite-servico', 'formulario-acompanhamento-servico', 'acompanhamento-servico', 'formulario-cadastro-terceiro', 'assets/img/gps_blink.svg', 'confirm-email', 'terceiros-cadastro'];


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public title = "mottu-monitor";
  public userLogged: UsuarioLogged;
  public renderRouter= false;
  public userVehicle: VeiculoSuporte;
  public isLoggedIn = false;

  constructor(
    private internalAuthService: InternalAuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private keycloak: KeycloakService,
    private authService: AuthService,
    private userAgentService: UserAgentService,
    private observabilityService: ObservabilityService
  ) {
    this.matIconRegistry.addSvgIcon(
      "occ_guard",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/img/monitor_icone_vigia-02.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "occ_guard_black",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/img/monitor_icone_vigia-03.svg"
      )
    );

    this.userLogged = this.internalAuthService.userLogged?.dataResult;
    this.userVehicle = this.internalAuthService.veiculoLogado;
  }

  async ngOnInit() {
    this.getUserAgentData();

    const url = window.location.href;
    if (SKIP_AUTH.some(skip => url.includes(skip))) {
      this.renderRouter = true;
      return;
    }

    this.isLoggedIn = await this.keycloak.isLoggedIn();

    if (this.isLoggedIn) {
      const token = await this.keycloak.getToken();

      this.authService.credentials = {
        accessToken: token,
      };

      const [userInfo, _] = await Promise.all([
        this.authService.getUserInfo(environment.keycloak),
        this.internalAuthService.authorize(),
      ]);

      this.renderRouter = true;

      this.authService.user = userInfo;

      this.observabilityService.init(userInfo);

      this.keycloak.keycloakEvents$.subscribe({
        next: async event => {
          if (event.type == KeycloakEventType.OnTokenExpired) {
            await this.updateToken();
          }
          if (event.type == KeycloakEventType.OnAuthRefreshError || event.type == KeycloakEventType.OnAuthError) {
            await this.keycloak.login();
          }
        },
      });
    } else {
      await this.keycloak.login();
    }
  }

  private async updateToken(): Promise<void> {
    await this.keycloak.updateToken();

    const newToken = await this.keycloak.getToken();
    this.authService.credentials = {
      ...this.authService.credentials,
      accessToken: newToken,
    };
  }

  private getUserAgentData() {
    this.userAgentService.saveUserAgentToLocalStorage();
  }
}

